import { changePageTitle } from '@/shared/utils'

export default {
  data () {
    return {
      pageName: ''
    }
  },
  props: {
    newsroom: {
      type: Object,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    changePageTitle(`${this.newsroom.name}`)
  }
}
