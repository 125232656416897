<template>
  <div class="content-wrapper pad">
    <brand-files-manager
      v-loading="form.isLoading"
      :brand-id="newsroom.brand.id"
      :value="form.files"
      @input="$emit('update', { files: $event })"
    />
  </div>
</template>

<script>
import NewsroomChild from '@/pages/newsroom/NewsroomChild'
import BrandFilesManager from '@hypefactors/shared/js/components/forms/BrandFilesManager'

export default {
  name: 'NewsroomEditAssets',
  components: { BrandFilesManager },
  extends: NewsroomChild,

  props: {
    form: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      pageName: 'Assets'
    }
  }
}
</script>
